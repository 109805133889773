<template>
  <div class="public_main_bgc">
    <div class="public_main_div researchCenter">
      <div class="top_div">
        <div class="banner_box">
          <img :src="list_banner" alt="" />
        </div>
        <div class="sousuoBox">
          <div class="sousuo_nav">
            <div class="sousuo_box">
              <div v-for="(item, index) in navArr" :key="index" class="sousuo_item"
                :class="{ active: item.id == reqObj.navActive }" @click="toggleType(item)">
                {{ item.name }}
              </div>
            </div>
            <div v-if="false" class="sousuo_right">
              <el-select v-model="reqObj.all_web_id" placeholder="区域选择" clearable size="small" style="width: 100px"
                @change="areaChange">
                <el-option v-for="item in area_option" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="filter_select_box">
            <div v-if="reqObj.navActive == 2" class="filter_item">
              <div class="name_box" style="width: 200px;">授课方式 :</div>
              <div class="filter_div_box">
                <div v-for="(item, index) in classType" :key="index" class="filter_div_item"
                  :class="{ active: classActive == item.id }" @click="classSelect(item.id)">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div v-if="classActive == 1" class="filter_item">
              <div class="name_box">类型 :</div>
              <div class="filter_div_box">
                <div class="filter_div_item" :class="{ active: reqObj.search_type == '' }" @click="handleSelect('', 1)">
                  全部
                </div>
                <div v-for="(item, index) in typeOption_3" :key="index" class="filter_div_item"
                  :class="{ active: reqObj.search_type == item.id }" @click="handleSelect(item.id, 1)">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div v-if="reqObj.navActive == 1" class="filter_item">
              <div class="name_box">类型 :</div>
              <div class="filter_div_box">
                <div class="filter_div_item" :class="{ active: reqObj.search_type == '' }" @click="handleSelect('', 1)">
                  全部
                </div>
                <div v-for="(item, index) in typeOption_1" :key="index" class="filter_div_item"
                  :class="{ active: reqObj.search_type == item.id }" @click="handleSelect(item.id, 1)">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="filter_item">
              <div class="name_box">学段 :</div>
              <div class="filter_div_box">
                <div class="filter_div_item" :class="{ active: reqObj.search_stage_id == '' }"
                  @click="handleSelect('', 2)">
                  全部
                </div>
                <div v-for="(item, index) in stage_option" :key="index" class="filter_div_item"
                  :class="{ active: reqObj.search_stage_id == item.id }" @click="handleSelect(item.id, 2)">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="filter_item">
              <div class="name_box">学科 :</div>
              <div class="filter_div_box">
                <div class="filter_div_item" :class="{ active: reqObj.search_subject_id == '' }"
                  @click="handleSelect('', 3)">
                  全部
                </div>
                <div v-for="(item, index) in subjectData" :key="index" class="filter_div_item" :class="{
                  active: reqObj.search_subject_id == item.subjectId,
                }" @click="handleSelect(item.subjectId, 3)">
                  {{ item.subjectName }}
                </div>
              </div>
            </div>
            <div v-if="classActive != 1" class="filter_item">
              <div class="name_box">状态 :</div>
              <div class="filter_div_box">
                <div v-for="(item, index) in status_option" :key="index" class="filter_div_item"
                  :class="{ active: reqObj.search_status == item.id }" @click="handleSelect(item.id, 4)">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="filter_item">
              <div class="name_box active">查找 :</div>
              <div class="filter_div_box active">
                <div class="filter_search_box">
                  <el-input placeholder="请输入搜索关键词" clearable v-model.trim="reqObj.search_title" class="input-with-select"
                    prefix-icon="el-icon-search" @keyup.enter.native="handleFilter()">
                    <el-button slot="append" type="primary" @click="handleFilter()">搜索</el-button>
                  </el-input>
                </div>
                <div v-if="reqObj.navActive != 1 && false" class="filter_search_box">
                  <el-input placeholder="通过访问码查找" clearable v-model="reqObj.search_visit_code" class="input-with-select"
                    prefix-icon="el-icon-search" @keyup.enter.native="handleFilter()">
                    <el-button slot="append" class="active" type="primary" @click="handleFilter()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table_box" v-loading="listLoading">
        <div class="list_box">
          <template v-for="(item, index) in tableData">
            <PublicTtemDiv :key="index"
              :navActive="reqObj.navActive == 1 ? reqObj.navActive : reqObj.navActive == 2 && classActive == 1 ? 3 : 2"
              :currentIndex="index" :item="item">
            </PublicTtemDiv>
          </template>
          <!-- <div v-if="!listLoading && !tableData.length" class="empty_div">暂无数据</div> -->
          <CommonEmpty v-if="!listLoading && !tableData.length" hVal="200px"></CommonEmpty>
        </div>

        <div v-show="ListTotal" class="pagina_div">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="reqObj.page" :page-sizes="[20, 40, 60, 80, 100]" :page-size="reqObj.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="ListTotal">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as researchApi from "@/api/researchApi.js";
import list_banner from "@/assets/list_banner.png";
import avator from "@/assets/images/avator.png";
import { deepClone } from "@/utils/utils.js";

const default_req_obj = {
  navActive: 1, // 当前模块
  page: 1,
  limit: 20,
  search_type: "", // 类型,
  search_stage_id: "", // 搜索学段id,
  search_subject_id: "", // 搜索科目id,
  search_status: "", // 搜索状态   1进行中 2未开始 3已结束,
  search_title: "", // 搜索标题,
  search_visit_code: "", // 访问码,
  all_web_id: 2, // 区域选择,
};
export default {
  components: {},
  data() {
    return {
      list_banner: list_banner,
      avator: avator,
      classActive: 1,
      classType: [
        { name: "录播课", id: 1 },
        { name: "直播课", id: 2 },
      ],
      navArr: [
        { name: "教研活动", id: 1 },
        { name: "四名讲堂", id: 2 },
        // { name: "教研直播", id: 2 },
        // { name: "教学视频", id: 3 },
      ],
      area_option: [
        {
          value: 1,
          label: "优秀推荐",
        },
        {
          value: 2,
          label: "本区域",
        },
      ],
      typeOption_1: [],
      typeOption_3: [
        {
          id: "1",
          name: "上课",
        },
        {
          id: "2",
          name: "说课",
        },
        {
          id: "3",
          name: "讲座",
        },
        // {
        //   id: "4",
        //   name: "听评课",
        // },
        // {
        //   id: "5",
        //   name: "集中备课",
        // },
        {
          id: "6",
          name: "其他",
        },
      ],
      status_option: [
        {
          id: "",
          name: "全部",
        },
        {
          id: "2",
          name: "进行中",
        },
        {
          id: "1",
          name: "未开始",
        },
        {
          id: "3",
          name: "已结束",
        },
      ],
      stage_option: [],
      subjectData: [],
      reqObj: Object.assign({}, deepClone(default_req_obj)),
      listLoading: true, //表格Loading
      tableData: [], //表格数据
      ListTotal: 0, //总条数
    };
  },
  watch: {
    $route: {
      handler(newVal, oldval) {
        if (newVal.query.stage_id) {
          this.$set(this.reqObj, "search_stage_id", newVal.query.stage_id);
          this.handleFilter();
        }
      },
      deep: true,
    },
  },
  methods: {
    classSelect(id) {
      this.reqObj.search_type = ""
      this.reqObj.search_stage_id = ""
      this.reqObj.search_subject_id = ""
      this.reqObj.search_status = ""
      this.reqObj.search_title = ""
      this.classActive = id
      this.handleFilter()
    },
    // 区域改变
    areaChange() {
      this.handleFilter();
    },
    // 列表数据
    async freshListData() {
      this.listLoading = true;
      let requestData = null;
      try {
        let hoststs = window.location.host;
        if (hoststs == 'www.teachercamp.net' || hoststs == 'msgzs.teachercamp.net' || hoststs == 'jypthouduan.co' || hoststs == 'jypthouduan.co:81' || hoststs == 'a-hlwjy.dc-it.cn' || hoststs == 'hlwjy.dc-it.cn' || hoststs == 'jypn.teachercamp.net') {
          // if (hoststs == 'jypthouduan.co') {
          let req_obj = deepClone(this.reqObj);
          req_obj['search_type2'] = req_obj.search_type;
          switch (Number(req_obj.navActive)) {
            case 1:
              requestData = await researchApi.get_activities_2_list(req_obj);
              break;
            case 2:
              if (this.classActive == 1) {
                req_obj['search_live'] = 1;
                requestData = await researchApi.get_moreLives_2_list(req_obj);
              } else {
                req_obj['search_live'] = 2;
                requestData = await researchApi.get_moreLives_2_list(req_obj);
              }
              break;
            // case 3:

            //   break;
            default:
              break;
          };
        } else {
          switch (Number(this.reqObj.navActive)) {
            case 1:
              requestData = await researchApi.get_activities_list(this.reqObj);
              break;
            case 2:
              requestData = await researchApi.get_moreLives_list(this.reqObj);
              break;
            case 3:
              requestData = await researchApi.get_courses_more_list(this.reqObj);
              break;
            default:
              break;
          };
        };

        let resdata = requestData.data.data;
        this.tableData = resdata.data;
        this.ListTotal = resdata.total;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      } catch (err) {
        this.tableData = [];
        this.ListTotal = 0;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }
    },
    // 列表
    handleFilter() {
      this.$set(this.reqObj, "page", 1);
      this.freshListData();
    },
    // 获取学科
    getSubjectList() {
      let params = {
        pharseid: this.reqObj.search_stage_id,
      };
      researchApi
        .get_subject_list(params)
        .then((res) => {
          let resdata = res.data;
          this.subjectData = resdata.data;
        })
        .catch((err) => {
          this.subjectData = [];
        });
    },
    // 获取学段
    getStateList() {
      researchApi.getStage_list().then((res) => {
        let resdata = res.data;
        this.stage_option = resdata.data;
      });
    },
    // 教研活动 类型
    getGetActivityType() {
      researchApi.get_get_activity_type().then((res) => {
        let resdata = res.data;
        this.typeOption_1 = resdata.data;
      });
    },
    // 条件筛选
    handleSelect(item, num) {
      switch (Number(num)) {
        case 1:
          this.$set(this.reqObj, "search_type", item);
          break;
        case 2:
          this.$set(this.reqObj, "search_stage_id", item);
          this.$set(this.reqObj, "search_subject_id", "");
          if (!item) {
            this.subjectData = [];
          } else {
            this.getSubjectList();
          }
          break;
        case 3:
          this.$set(this.reqObj, "search_subject_id", item);
          break;
        case 4:
          this.$set(this.reqObj, "search_status", item);
          break;
        default:
          break;
      }
      this.handleFilter();
    },
    // 改变 条数
    handleSizeChange(val) {
      this.$set(this.reqObj, "page", 1);
      this.$set(this.reqObj, "limit", val);
      this.freshListData();
    },
    // 改变 页数
    handleCurrentChange(val) {
      this.$set(this.reqObj, "page", val);
      this.freshListData();
    },
    // 切换类型
    toggleType(item) {
      if (item.id == this.reqObj.navActive) {
        return false;
      }
      this.reqObj = Object.assign({}, deepClone(default_req_obj));
      this.$set(this.reqObj, "navActive", item.id);
      this.classActive = 1
      let query = this.$route.query;
      this.$router.replace({
        query: {
          ...query,
          navActive: this.reqObj.navActive,
        },
      });
      this.handleFilter();
    },
  },
  created() {
  },
  mounted() {
    let query = this.$route.query;
    if (query && query.navActive) {
      this.$set(this.reqObj, "navActive", query.navActive);
    }
    if (query && query.stage_id) {
      this.$set(this.reqObj, "search_stage_id", query.stage_id);
    }
    this.getStateList();
    this.handleFilter();
    this.getGetActivityType();
  },
};
</script>

<style lang="less" scoped>
// 教研展示
.researchCenter {
  padding-top: 20px;
  box-sizing: border-box;
}

.top_div {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  div {
    box-sizing: border-box;
  }

  .banner_box {
    width: 100%;
    height: 178px;
    // background-image: url("~@/assets/images/myImg/zongji1.png");
    background-color: #1f7eff;
    background-size: 100% 100%;

    >img {
      width: 100%;
      height: 100%;
    }
  }

  .sousuoBox {
    width: 100%;
    // height: 342px;
    background-color: #fff;
    padding: 0 32px;
  }

  .sousuo_nav {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sousuo_box {
      height: 100%;
      display: flex;
      align-items: center;

      .sousuo_item {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 16px;
        font-weight: normal;
        color: #111a38;
        margin-right: 60px;
        border-bottom: 2px solid #fff;
        cursor: pointer;

        &.active {
          font-weight: bold;
          color: #3489ff;
          border-bottom: 2px solid #3489ff;
        }
      }
    }

    .sousuo_right {
      /deep/.el-input__inner {
        background-color: #f4fbff;
      }
    }
  }

  .filter_select_box {
    border-top: 1px solid #dae7ed;
    padding-top: 28px;

    .filter_item {
      display: flex;

      .name_box {
        text-align: right;
        flex: 0 1 80px;
        width: 70px;
        font-size: 15px;
        line-height: 28px;
        font-weight: normal;
        color: rgba(102, 113, 149, 1);
        margin-bottom: 16px;
        margin-right: 10px;

        &.active {
          line-height: 40px;
          margin-bottom: 24px;
        }
      }

      .filter_div_box {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        &.active {
          justify-content: space-between;
        }
      }

      .filter_div_item {
        height: 28px;
        padding: 0 16px;
        line-height: 28px;
        color: #111a38;
        border-radius: 14px;
        margin-right: 10px;
        margin-bottom: 16px;
        cursor: pointer;

        &.active {
          font-weight: bold;
          color: #3489fe;
          background: rgba(228, 242, 250, 1);
        }
      }

      .filter_search_box {
        /deep/ .input-with-select {
          width: 288px;
          height: 40px;
          border-radius: 0 6px 6px 0;
          overflow: hidden;

          .el-input__inner {
            background-color: #f4fbff;
          }

          .el-button {
            width: 72px;
            background-color: #3489fe;
            color: #fff;
            font-size: 15px;
            font-weight: normal;
            color: rgba(255, 255, 255, 1);
            border-radius: 0;
            box-sizing: border-box;

            &.active {
              background-color: #ff8201;
            }
          }
        }
      }
    }
  }
}

.table_box {
  width: 100%;
}

.empty_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-weight: bold;
  font-size: 26px;
}

.list_box {
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  min-height: 416px;
  display: flex;
  flex-wrap: wrap;

  div {
    box-sizing: border-box;
  }
}
</style>
